"use client";

import * as React from "react";
import { parseISO, format } from "date-fns";
import {
  Box,
  Button,
  FormControl,
  Typography,
  Divider,
  Card,
  Grid,
  Chip,
  TextField,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  CircularProgress,
  Stack,
  Snackbar,
  useTheme,
  useMediaQuery
} from "@mui/material";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useQRCode } from "next-qrcode";
import { useAuthStore } from "@/stores/authStore";
import { shallow } from "zustand/shallow";
import useUserReferral from "@/hooks/fetchers/useUserReferral";
import useReferralHistory from "@/hooks/fetchers/useReferralHistory";
import { useSnackbar } from "notistack";
import { useAffilateStore } from "@/stores/affilateStore";
import useMe from "@/hooks/fetchers/useMe";

type Props = {};

const Affilate = (props: Props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { refresh } = useMe();
  const [accept] = useAffilateStore((state) => [state.accept], shallow);
  const [user] = useAuthStore((state) => [state.user], shallow);
  const { data: referrallist, isLoading: isReferralListLoading } =
    useUserReferral();
  const { data: referralhistory, isLoading: isReferralHistoryLoading } =
    useReferralHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { Image } = useQRCode();

  function claimAffilate() {
    if (
      referrallist?.sum_money != undefined &&
      parseFloat(referrallist?.sum_money) > 0
    ) {
      accept()
        .then((res: any) => {
          if (res?.errMessage) {
            enqueueSnackbar(res.errMessage, {
              variant: "error",
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
            });
          } else {
            enqueueSnackbar("รับค่าคอมมิชชั่นสำเร็จ", {
              variant: "success",
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
            });
            refresh();
          }
        })
        .catch((err) => {
          enqueueSnackbar("This is a success message!", {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        });
    } else {
      enqueueSnackbar("ไม่มีรายได้สำหรับรับค่าคอมมิชชั่น", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    }
  }

  interface Column {
    id: "date" | "name";
    label: string;
    minWidth?: number;
    align?: "right";
    format?: (value: number) => string;
  }

  const columns: readonly Column[] = [
    { id: "date", label: "วันที่สมัคร", minWidth: 100 },
    { id: "name", label: "ยูสเซอร์", minWidth: 100 },
  ];

  interface Data {
    date: string;
    name: string;
  }

  function createData(date: string, name: string): Data {
    return { date, name };
  }

  const rows: any[] = [];

  referralhistory?.map((item_history: any) =>
    rows.push(
      createData(
        format(new Date(item_history?.created_at), "dd/MM/yyyy"),
        item_history?.gameusername
      )
    )
  );

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  if (isReferralListLoading) {
    return (
      <>
        <Grid sx={{ textAlign: "center" }}>
          <CircularProgress></CircularProgress>
        </Grid>
      </>
    );
  } else {
    const haveExpert = referrallist?.sum_expert_last !== null && referrallist?.sum_expert_last !== undefined

    return (
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <Box>
            <Divider>
              <Chip
                label="แนะนำเพื่อน"
                color="primary"
                size="medium"
                style={{ fontSize: "18px", fontWeight: 700 }}
              />
            </Divider>
          </Box>
        </Grid>
        <Grid item xs={12} md={12}>
          <Stack
            sx={{
              overflow: "hidden",
              borderRadius: "16px",
            }}
            boxShadow={theme.shadows[8]}
            my={2}
          >
            <Typography
              sx={{
                p: 1,
                textAlign: "center",
                bgcolor: theme.mineColor["paper-soft"],
              }}
            >
              รายได้ทั้งหมด
            </Typography>
            <Typography
              sx={{
                color: theme.palette.primary.main,
                p: 2,
                textAlign: "center",
                bgcolor: theme.mineColor["paper-deep"],
              }}
              variant="h5"
              fontWeight={"bold"}
            >
              {referrallist?.sum_money}
            </Typography>
          </Stack>
          <Stack
            sx={{
              overflow: "hidden",
              borderRadius: "16px",
            }}
            boxShadow={theme.shadows[8]}
            my={2}
          >
            <Typography
              sx={{
                p: 1,
                textAlign: "center",
                bgcolor: theme.mineColor["paper-soft"],
              }}
            >
              จำนวนผู้สมัครทั้งหมด
            </Typography>
            <Typography
              sx={{
                color: theme.palette.primary.main,
                p: 2,
                textAlign: "center",
                bgcolor: theme.mineColor["paper-deep"],
              }}
              variant="h5"
              fontWeight={"bold"}
            >
              {referrallist?.referral_count}
              <Box
                ml={0.5}
                color={theme.palette.primary.main}
                fontSize={16}
                component={"span"}
              >
                คน
              </Box>
            </Typography>
          </Stack>
          {
            haveExpert && (
              <>
                <Stack
                  sx={{
                    overflow: "hidden",
                    borderRadius: "16px",
                  }}
                  boxShadow={theme.shadows[8]}
                  my={2}
                >
                  <Typography
                    sx={{
                      p: 1,
                      textAlign: "center",
                      bgcolor: theme.mineColor["paper-soft"],
                    }}
                  >
                    สรุปยอดพันธมิตรครั้งล่าสุด
                  </Typography>
                  <Typography
                    sx={{
                      color: theme.palette.primary.main,
                      p: 2,
                      textAlign: "center",
                      bgcolor: theme.mineColor["paper-deep"],
                    }}
                    variant="h5"
                    fontWeight={"bold"}
                  >
                    {referrallist?.sum_expert_last}
                    <Box
                      ml={0.5}
                      color={theme.palette.primary.main}
                      fontSize={16}
                      component={"span"}
                    >
                      คน
                    </Box>
                  </Typography>
                </Stack>
                <Stack
                  sx={{
                    overflow: "hidden",
                    borderRadius: "16px",
                  }}
                  boxShadow={theme.shadows[8]}
                  my={2}
                >
                  <Typography
                    sx={{
                      p: 1,
                      textAlign: "center",
                      bgcolor: theme.mineColor["paper-soft"],
                    }}
                  >
                    ยอดพันธมิตรประจำเดือนที่ผ่านมา
                  </Typography>
                  <Typography
                    sx={{
                      color: theme.palette.primary.main,
                      p: 2,
                      textAlign: "center",
                      bgcolor: theme.mineColor["paper-deep"],
                    }}
                    variant="h5"
                    fontWeight={"bold"}
                  >
                    {referrallist?.sum_expert_previous_month}
                    <Box
                      ml={0.5}
                      color={theme.palette.primary.main}
                      fontSize={16}
                      component={"span"}
                    >
                      คน
                    </Box>
                  </Typography>
                </Stack>
              </>
            )
          }
        </Grid>
        <Grid item xs={12} md={12}>
          <Box textAlign="center">
            <Image
              text={referrallist?.link_refer || ""}
              options={{
                type: "image/jpeg",
                quality: 1,
                errorCorrectionLevel: "M",
                margin: 3,
                scale: 4,
                width: 280,
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box textAlign="center" gap={2}>
            <FormControl sx={{ width: "65%" }}>
              <TextField
                fullWidth
                value={referrallist?.link_refer || ""}
                id="fullWidth"
                size="small"
              />
            </FormControl>
            <Button
              startIcon={<ContentCopyIcon />}
              variant="contained"
              onClick={() =>
                navigator.clipboard.writeText(referrallist?.link_refer || "")
              }
            >
              คัดลอก
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box mt={2} textAlign="center" sx={{ pb: 2 }}>
            <Button
              variant="contained"
              size="large"
              onClick={() => claimAffilate()}
            >
              รับค่าคอมมิชชั่น
            </Button>
          </Box>
        </Grid>

        <Grid item xs={3} md={3}>
          <Box textAlign="center">
            <img
              src="/assets/icons/categories/slot_new.png"
              width="60"
              loading="lazy"
            />
            <Typography align="center">สล็อต</Typography>
            <Typography align="center">
              {referrallist?.affiliate_slot} %
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={3} md={3}>
          <Box textAlign="center">
            <img
              src="/assets/icons/categories/casino_new.png"
              width="60"
              loading="lazy"
            />
            <Typography align="center">คาสิโน</Typography>
            <Typography align="center">
              {referrallist?.affiliate_casino || ""} %
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={3} md={3}>
          <Box textAlign="center">
            <img
              src="/assets/icons/categories/sport_new.png"
              width="60"
              loading="lazy"
            />
            <Typography align="center"> กีฬา</Typography>
            <Typography align="center">
              {referrallist?.affiliate_sport || ""} %
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={3} md={3}>
          <Box textAlign="center">
            <img
              src="/assets/icons/categories/huay_new.png"
              width="60"
              loading="lazy"
            />
            <Typography align="center">หวย</Typography>
            <Typography align="center">
              {referrallist?.affiliate_lotto || ""} %
            </Typography>
          </Box>
        </Grid>
        <Paper />
        <Grid item xs={12}>
          <Box mt={2} textAlign="center">
            <Divider>
              <Chip
                label="ประวัติการแนะนำเพื่อน"
                color="primary"
                size="medium"
                style={{ fontSize: "16px", fontWeight: 500 }}
              />
            </Divider>
          </Box>
          <Box mt={2} textAlign="center">
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
              <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          sx={{ minWidth: column.minWidth, color: (theme) => theme.gradient[700], bgcolor: (theme) => theme.gradient[610] }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row.code}
                          >
                            {columns.map((column) => {
                              const value = row[column.id];
                              return (
                                <TableCell key={column.id} align={column.align}>
                                  {column.format && typeof value === "number"
                                    ? column.format(value)
                                    : value}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={'Rows:'}
                labelDisplayedRows={({ from, to, count }) => isMobile ? `Page: ${page + 1}/${Math.ceil(rows.length / rowsPerPage)}` : `${from}–${to} of ${count !== -1 ? count : `more than ${to}`}`}
              />
            </Paper>
          </Box>
        </Grid>
      </Grid>
    );
  }
};

export default Affilate;
